const convertAbsoluteToRelative = (link) => {
  if (link && typeof link === 'string') {
    const hostname = 'citysprings\\.com';
    const pattern = new RegExp(
      `^(internal:|(https?://)?(www\\.)?${hostname})/`,
      'i'
    );
    link = link.replace(pattern, '/');
  }
  return link;
};

export {
  convertAbsoluteToRelative
};
