import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WYSIWYG from '../wysiwyg/wysiwyg';

import styles from '../content-display/content-display.module.scss';

const ContentWrapper = ({ title, body, children, full = true, sidebar }) => {
  // Conditionally add the full layout option, it defaults to true.
  const layoutClassNames = classNames(styles.contentWrapper, {
    [styles.contentWrapperFull]: full
  });

  return (
    <React.Fragment>
      <article className={styles.article} aria-label={title}>
        <div className={layoutClassNames}>
          {body && (
            <WYSIWYG>
              <div
                className={styles.body}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </WYSIWYG>
          )}
          {children}
        </div>
        {sidebar}
      </article>
    </React.Fragment>
  );
};

ContentWrapper.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node,
  sidebar: PropTypes.node,
  full: PropTypes.bool
};

export default ContentWrapper;
