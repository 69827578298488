import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import DefaultLayout from '../components/default-layout/default-layout';
import ContentWrapper from '../components/content-wrapper/content-wrapper';
import SEO from '../components/seo/seo';

const CheckoutPage = ({ data }) => {
  return (
    <DefaultLayout data={data}>
      <SEO
        title="City Springs Checkout"
        description="Purchase event tickets to City Springs."
        siteConfig={data.site.siteMetadata}
        pageUrl="/checkout"
      />
      <ContentWrapper>
        <iframe
          title='Checkout'
          name='SpektrixIFrame'
          id='SpektrixIFrame'
          frameBorder='0'
          onLoad={(event)=> event.target.className = 'loaded'}
          src={`${ data.site.siteMetadata.spektrixAPI }/${ data.site.siteMetadata.spektrixClientName }/website/Secure/Checkout/v2?resize=true`}>
        </iframe>
      </ContentWrapper>
    </DefaultLayout>
  );
};

CheckoutPage.propTypes = {
  data: PropTypes.object
};

export default CheckoutPage;

export const query = graphql`
  query CheckoutPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
        spektrixAPI
      }
    }
  }
`;
